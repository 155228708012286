import React from 'react'
import { fontSizeMap } from '~/components/inputs/InputUtils'

interface Props
  extends InputProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLLabelElement>,
      HTMLLabelElement
    > {
  dangerouslySet?: boolean
  error?: string | string[]
}

const FormError: React.FC<Props> = ({
  className,
  dangerouslySet,
  inputType = 'form',
  error,

  ...other
}) => {
  if (!error) {
    return null
  }

  const errorStr = Array.isArray(error) ? error[0] : error

  return (
    <label
      className={
        `${fontSizeMap[inputType]} bg-transparent font-sansSerif text-danger antialiased` +
        (className ? ` ${className}` : '')
      }
      dangerouslySetInnerHTML={
        dangerouslySet
          ? {
              __html: errorStr,
            }
          : undefined
      }
      {...other}
    >
      {!dangerouslySet ? errorStr : null}
    </label>
  )
}

export default FormError
