export const heightMap = {
  filters: 'h-inputFilters',
  normal: 'h-inputNormal',
  form: 'h-inputForm',
}

export const fontSizeMap = {
  filters: 'text-p3',
  normal: 'text-p2',
  form: 'text-p2',
}
